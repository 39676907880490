export default {
  state: {
    menuList: {
        Platform:    { name: 'Platform', index: 'Platform', linkUrl: 'https://platform.global-ptt.com/' },
   Dispatch: { name: 'Dispatch', index: 'Dispatch', linkUrl: 'https://dispatch.global-ptt.com/' },
   Radios: { name: 'Radios', index: 'Radios', linkUrl: 'https://txq-radio.com/' },
      Sims: { name: 'Sims', index: 'Sims', linkUrl: 'https://iot.global-ptt.com/#/searchindex' },
   Catalog: {name: 'Catalog', index: 'Catalog', linkUrl:'/global-ptt_product_catalog.pdf'}
        },
        contact: {
            email: 'aaa@abc.com',
            mobile: '+861234456'
    }
  },
    getters: {
        getMenus: (state) => {
            let menus = []
            // let keys = state.menuList.keys()
            for (let key in state.menuList) {
                menus.push(state.menuList[key])
            }
            return menus
        }

  },
  mutations: {

  },
  actions: {
  },
    modules: {

  }
}