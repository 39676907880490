import cookies from 'vue-cookies'

const TokenKey = 'Authentication'

export function getToken() {
  return cookies.get(TokenKey)
}

export function setToken(token) {
  return cookies.set(TokenKey, token)
}

export function removeToken() {
  return cookies.remove(TokenKey)
}

export function isAuthenticated() {
    return cookies.isKey(TokenKey)
}