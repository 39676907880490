import store from ".."

export default {
  state: {
        zhcn: {
      slideText: '拖动滑块验证',
      menus: {
        MyGroups:    { name: 'MyGroups', index: 'MyGroups', path: 'https://platform.global-ptt.com/' },
        UserCenter: { name: 'UserCenter', index: 'UserCenter', path: 'https://dispatch.global-ptt.com/' },
      },
    },
    en: {
      slideText: 'Drag the slider below to complete the puzzle',
      menus: {
        MyGroups:    { name: '我的群组', index: 'MyGroups', path: 'https://platform.global-ptt.com/' },
        UserCenter: { name: '用户中心', index: 'UserCenter', path: 'https://dispatch.global-ptt.com/' },
      },
    }
  },
  getters: {
    getClientMenus: (state, lang) => {

    }
  },
  mutations: {

  },
  actions: {
    getClientMenus: (context) => {
      let lang = context.getters.lang
      console.log(lang, context)
      let data = context.state[lang]
      let menus = []
      // let keys = state.menuList.keys()
      for (let key in data.menus) {
          menus.push(data.menus[key])
      }
      return menus
    }
  },
    modules: {

  }
}