export default {
  state: {
        zhcn: {
        slideText: '拖动滑块验证'
    },
    en: {
        slideText: 'Drag the slider below to complete the puzzle'
    }
  },
    getters: {

  },
  mutations: {

  },
  actions: {
  },
    modules: {

  }
}