import { createApp } from 'vue'
import LoginAgent from "./LoginAgent.vue";

export default() => {
    const mountNode = document.createElement('div')
    const instance = createApp(LoginAgent, {
        loginSuccess: () => {
            console.log('login success')
            handleClick()
        }
    })
    const handleClick = () => {
        console.log('my click sucess')
        instance.unmount(mountNode)
    }

    document.body.appendChild(mountNode)
    instance.mount(mountNode)
    return instance
}
